import React, { useEffect, useState } from 'react'
import { getData, postData } from '../utils/FetchUtils';
import { Avatar, Box, CircularProgress, List, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { TempBrandButton, TempSecondaryBrandButton } from '../CoreComponents/BrandButton';
import { SongListItem } from './SongListItem';
import { useAsyncAction } from '../../hooks/useAsyncAction';
import { ButtonSize } from '../../constants/buttonConstants';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelCircleIcon from '@mui/icons-material/CancelOutlined';
import AdditionalAccountHolders from './AdditionalAccountHolders';
import { BrandModal } from '../CoreComponents/BrandModal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mergeSongToolbar: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(4),
        alignItems: 'baseline',
        paddingTop: theme.spacing(3),
        justifyContent: 'flex-start'
    },
    songDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    root: {
        width: '100%',
        height: '60vh',
        overflowY: 'scroll',
        padding: '8px',

        '&::-webkit-scrollbar': {
            width: '13px',
        },
        '&::-webkit-scrollbar-track': {
            background: theme.palette.background.default,
            borderRadius: '3px',
            border: `1px solid ${theme.palette.primary.lightGrayBorder}`,

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.darkGray,
            borderRadius: '10px',

        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.darkGrayNuance, 
        },
    },
    mergeSongContainer: {
        "& label": {
            marginBottom: theme.spacing(3.5),
        },
        width: '50vw'
    },
    alert: {
        marginTop: theme.spacing(3.5),
        display: 'flex',
        justifyContent: 'center',
    },
    songContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        minWidth: '20vw',
        maxWidth: '38vw'
    },
    songActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& .MuiButton-outlinedPrimary:hover': {
            border: `1px solid ${theme.palette.primary.contrastText}`
        }
    },
    mergeHeadingsToolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        "& .MuiButtonBase-root": {
            marginRight: theme.spacing(2)
        }
    },
    coverArt: {
        width: '26px',
        height: '26px'
    },
    popOver: {
        "& .MuiPaper-root": {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    songMergeInfo: {
        maxHeight: '250px',
        position: 'absolute',
        zIndex: '1',
        backgroundColor: theme.palette.background.default,
        padding: '10px',
    },
    greenText: {
        color: theme.palette.primary.main,
    },
    redText: {
        color: theme.palette.primary.alert
    },
    tooltipItems: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.lightYellow}`,
        borderRadius: '16px',
    
        textAlign: 'left',
        font: 'normal normal normal 16px/26px Roboto',
        padding: '12px',
    },
    accountHoldersText: {
        '& .MuiTypography-root': {
            font: 'normal normal bold 14px/21px Roboto',
            textAlign: 'left',
            letterSpacing: '0px',
        },
    },
    songTitleText: {
        '& .MuiTypography-root': {
            font: 'normal normal bold 16px/21px Roboto',
            textAlign: 'left',
            letterSpacing: '0px',
        },  
        display: 'flex',
        flexDirection: 'row',
    },
    verifiedBox: {
        color: theme.palette.primary.main,
        marginLeft: '3px',
        display: 'flex',
    },
    unverifiedBox: {
        color: theme.palette.primary.delete,
        marginLeft: '3px',
        display: 'flex',
    },
    verifiedIcon: {
        marginLeft: '3px',
        marginRight: '3px',
    },
    unverifiedIcon: {
        marginLeft: '3px',
        marginRight: '3px',
    },
    heading: {
        height: '40px',
    },
    warningContainer: {
        width: 600,
        height: theme.spacing(32),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    warningText: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.attention,
        whiteSpace: 'nowrap',
        '& > .MuiTypography-root': {
            marginLeft: theme.spacing(1),
        },
    },
    warningIcon: {
        marginRight: '0.4vw',
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        "& button:first-child": {
            marginRight: theme.spacing(2),
        },
    },
    okayButton: {
        justifyContent: "flex-end",
        marginTop: theme.spacing(7),
        display: 'flex',
    },
    text: {
        font: 'normal normal normal 16px/26px Roboto',
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(5.8), 
    },
}));

const MergeSongModal = ({ song, onClose }) => {
    const styles = useStyles();
    const search = useState(song.displayName || '');
    const verified = song.spotifySongId ? true : false;

    const [songs, setSongs] = useState([]);
    const [selectedSongs, setSelectedSongs] = useState([]);
    const [alert, setAlert] = useState('');
    const [showMergeWarning, setShowMergeWarning] = useState(false);
    const [showUnverifiedSongMergeWarning, setShowUnverifiedSongMergeWarning] = useState(false);
    
    // 0 - Unverified into verified song
    // 1 - Verified into unverified song 
    const [unverifiedSongWarningType, setUnverifiedSongWarningType] = useState(0);

    // 0 - Account Holder Warning
    // 1 - Merging a verified song into an unverified one
    const [modalStep, setModalStep] = useState(0);

    function moveToSelectedSongs(selectedSong) {
        setSelectedSongs([...selectedSongs, selectedSong]);
    };

    function removeFromSelectedSongs(selectedSong) {
        setSelectedSongs(selectedSongs.filter(s => s.id !== selectedSong.id));
    };

    async function fetchSongs() {
        const exceptions = [...selectedSongs, song].map(e => e.id).join(",");
        const encodedSearch = encodeURIComponent(search[0] || '');
        const fetchedData = await getData(process.env.REACT_APP_SERVER_HOST + `/api/song/?offset=${0}&limit=${10}&filter=${encodedSearch}&exceptions=${exceptions}`);
        setSongs(fetchedData.items.filter(item => 
            !item.mergedSongs.some(sng => sng === song.displayName)
        ));
    }

    const { trigger: merge, loading: mergeLoading } = useAsyncAction(async () => {
        const mergeObject = {
            songId: song.id,
            songIdsToBeMerged: selectedSongs.map((s) => s.id)
        };

        try {
            await postData(process.env.REACT_APP_SERVER_HOST + '/api/song/merge-songs', mergeObject);

            onClose();
        } catch (error) {
            setAlert(error.message);
        }
    });

    // function merge() {
    //     const mergeObj = { songId: song.id, songIdsToBeMerged: selectedSongs.map(s => s.id) };
    //     postData(process.env.REACT_APP_SERVER_HOST + `/api/song/merge-songs`, mergeObj)
    //         .then(data => {
    //             if (data.error) {
    //                 setAlert(data.error);
    //                 return;
    //             }
    //             onClose();
    //         }, error => {
    //             setAlert(error.message);
    //         })
    // };

    useEffect(() => {
        fetchSongs();
    }, [search[0], selectedSongs]);

    useEffect(() => {
        setAlert('');
    }, [search[0], selectedSongs, songs]);

    const handleMerge = () => {
        let hasAccountHolderMismatch = false;
        let hasUnverifiedSongMismatch = false;

        for (const s of selectedSongs) {
            for (const ah of s.accountHolders) 
                // Check if there is a mismatch between the account holders
                if (!song.accountHolders.includes(ah)){
                    hasAccountHolderMismatch = true;
                    break;
                }

            // Check if the an unverified song is getting merged into a verified one
            if ((!s.spotifySongId && verified) || (s.spotifySongId && !verified)) {
                hasUnverifiedSongMismatch = true;
                if (!s.spotifySongId) setUnverifiedSongWarningType(0);
                else if (!verified) setUnverifiedSongWarningType(1);
            }
        }

        if(hasUnverifiedSongMismatch)
            setShowUnverifiedSongMergeWarning(true);

        if (hasAccountHolderMismatch) {
            setShowMergeWarning(true);
            setModalStep(0);
        } else if (hasUnverifiedSongMismatch && !hasAccountHolderMismatch) {
            setModalStep(1);
        } else {
            merge();
            setShowMergeWarning(false);
        }
    }

    const handleNextWarning = () => {
        if (modalStep === 0 && showUnverifiedSongMergeWarning)
            setModalStep(1);
        else
            merge();
    }
    
    const handleCloseWarning = () => {
        setShowMergeWarning(false); 
        setShowUnverifiedSongMergeWarning(false);
    }

    return (
        <div className={styles.mergeSongContainer}>
            <Typography variant='h5' component='div' color='primary'> Merge Song</Typography>
            <div className={styles.mergeSongToolbar}>
                <BrandSearchInput $value={search} />
                <div className={styles.songDetails}>
                    <ListItemIcon>
                        <Avatar
                            alt={`Avatar of ${song.name ? song.name + ' - ' : ''}${song.displayName}`}
                            src={song?.coverArt || null}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        <Box className={styles.songTitleText}>
                            <Typography>
                                {`${song.artistName ? song.artistName + ' - ' : ''}`}
                                {`${song.name ? song.name + ' - ' : ''}${song.displayName}`}
                            </Typography>
                            { verified ? 
                            <Box className={styles.verifiedBox}>
                                <Box className={styles.verifiedIcon}>
                                    <CheckCircleIcon/>
                                </Box>
                                <span>verified</span>
                            </Box>
                            : 
                            <Box className={styles.unverifiedBox}>
                                <Box className={styles.unverifiedIcon}>
                                    <CancelCircleIcon/>
                                </Box>
                                <span>unverified</span>
                            </Box>
                            }
                        </Box>
                        <AdditionalAccountHolders accountHolders={song.accountHolders} />
                    </ListItemText>
                </div>
            </div>
            <div className={styles.mergeSongToolbar}>
                <div className={styles.root}>
                    <div className={styles.mergeHeadingsToolBar}>
                        <Typography
                            variant='h6'
                            component='div'
                            color='primary'
                            className={styles.heading}
                        >
                            Songs
                        </Typography>
                        <TempBrandButton
                            size={ButtonSize.SMALL}
                            onClick={() => setSelectedSongs([...selectedSongs, ...songs])}
                        >
                            Add all
                        </TempBrandButton>
                    </div>
                    <List dense={true}>
                        {songs.map(song => {
                            return (
                                <SongListItem
                                    key={song.id}
                                    song={song}
                                    onClick={moveToSelectedSongs}
                                />
                            );
                        })}
                    </List>
                </div>
                <div className={styles.root}>
                    <Typography className={styles.heading} variant='h6' component='div' color='primary'>Songs to merge</Typography>
                    <List dense={true}>
                        {selectedSongs.map(song => {
                            return (
                                <SongListItem
                                    key={song.id}
                                    song={song}
                                    onClick={removeFromSelectedSongs}
                                />
                            );
                        })}
                    </List>
                </div>
            </div>
            {alert ?
                <div className={styles.alert}><BrandAlert >{alert}</BrandAlert></div>
                :
                null
            }
            <div className={styles.songActions}>
                <TempSecondaryBrandButton
                    size={ButtonSize.SMALL}
                    variant='outlined'
                    onClick={() => onClose()}
                >
                    Cancel
                </TempSecondaryBrandButton>
                <TempBrandButton
                    size={ButtonSize.SMALL}
                    disabled={selectedSongs.length === 0 || mergeLoading && !(showMergeWarning || showUnverifiedSongMergeWarning)}
                    onClick={handleMerge}
                >
                    {mergeLoading && !showMergeWarning && !showUnverifiedSongMergeWarning ? (
                        <CircularProgress size={18} />
                    ) : 'Merge'}
                </TempBrandButton>
            </div>
            <BrandModal 
                open={(showMergeWarning || showUnverifiedSongMergeWarning)} 
                onClose={handleCloseWarning}>
                <Box className={styles.warningContainer}>
                    <Box>
                        <Typography variant="h5" gutterBottom>
                            Warning
                        </Typography>
                        {   modalStep === 0 && (
                            <Box className={styles.accountHoldersText}>
                                <Box className={styles.warningText}>
                                    <WarningAmberIcon fontSize='large' />
                                    <Typography>You're trying to merge a song in a different Account Holder.</Typography>
                                </Box>
                                <Box className={styles.text}>
                                    <Typography variant="body1" gutterBottom>
                                        {song.accountHolders[0] ? song.accountHolders[0] : 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        into:
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <AdditionalAccountHolders 
                                        accountHolders={[...new Set(selectedSongs.map(song => song.accountHolders).flat())]}
                                        showAccountHoldersText={false}/>
                                    </Typography>
                                    <Typography variant="body1">
                                        Please check if this is correct.
                                    </Typography>
                                </Box>
                            </Box>)
                        }
                        {   modalStep === 1 && (
                            <Box className={styles.accountHoldersText}>
                                <Box className={styles.warningText}>
                                    <WarningAmberIcon fontSize='large' />
                                    <Typography>
                                      You're trying to merge 
                                      <span className={unverifiedSongWarningType ? styles.greenText : styles.redText}>
                                        {unverifiedSongWarningType ? ' verified ' : ' unverified '}
                                      </span> 
                                      song(s) into 
                                      <span className={unverifiedSongWarningType ? styles.redText : styles.greenText}>
                                        {unverifiedSongWarningType ? ' an unverified ' : ' a verified '}
                                      </span> 
                                      song.
                                    </Typography>
                                </Box>
                                <Box className={styles.text}>
                                    <Typography variant="body1">
                                        Please check if this is correct.
                                    </Typography>
                                </Box>
                            </Box>
                            )
                        }
                    </Box>
                    <Box className={styles.actionsWrapper}>
                        <TempSecondaryBrandButton onClick={handleCloseWarning}>
                           Cancel
                        </TempSecondaryBrandButton>
                        <TempBrandButton onClick={handleNextWarning} disabled={mergeLoading}>
                             {mergeLoading ? (
                                 <CircularProgress size={24} />
                                ) : 'Merge'}
                        </TempBrandButton>
                    </Box>
                </Box>
            </BrandModal>
        </div >
    );
}

export default MergeSongModal;